import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employes } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { jixbeeLogo } from 'images/koppelingen'
import Video from "components/video"

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Over Jixbee',
    description: 'De toekomst van personal payrolling = Pay On Demand. Via onze app hebben zij real-time inzicht in hun verdiensten op en kunnen deze flexibel en on-demand uitbetalen binnen enkele seconden. Zonder te hoeven wachten op de verloning òf administratieve handelingen vanuit de werkgever. Zo kunnen zij zich beter focussen op werk en hebben méér motivatie door directe compensatie.'
    }, {
      title: 'De werking van de koppeling met Jixbee',
      description: 'Met een koppeling tussen Employes, Jixbee en elk gewenste planningssoftware weet je zeker dat jouw team optimale financiële flexibiliteit ervaart. Tussentijdse betalingen worden automatisch verwerkt, zo blijven de administratieve processen &amp; pay-cyle zoals jij die gewend bent.',
    },{
      title: 'Functies van de koppeling',
      list: ['Automatisch inladen van de werknemers',
      'Uitbetalingen worden automatisch verwerkt op de loonstrook',
      'Creëer custom veldjes om de werking van Jixbee te personaliseren']
    }, {
      title: 'Beschikbaarheid en tarieven van de koppeling',
      description: 'De koppeling tussen Employes en Jixbee is geheel gratis.'
  }]
}

class Jixbee extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Jixbee & Employes | Performance management</title>
            <meta name="description" content="Een uitbreiding van je salarisadministratie met pay on demand management. Ontdekt de koppeling van Jixbee."/>
            <meta itemprop="name" content="Koppeling Jixbee & Employes | Performance management" />
            <meta itemprop="description" content="Een uitbreiding van je salarisadministratie met pay on demand management. Ontdekt de koppeling van Jixbee."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling jixbee padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Jixbee aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employes} alt="employes logo"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#474750" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={jixbeeLogo} alt="Jixbee logo"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            ),
                            video: (
                                <Video videoSrcURL={section.video} videoTitle="Koppeling Moneybird en Employes salarisadministratie"/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <a href="https://jixbee.com/" target="_blank" rel="nofollow">Lees meer over Jixbee</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Jixbee.propTypes = {
  data: PropTypes.object
}

export default Jixbee
